import React from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import RelatedProducts from "../../client/compositions/related-products"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import { PortfolioTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Project: PorscheLobby Page
 * @see https://zpl.io/bzgYOWl
 */
function ProjectPorscheLobbyPage() {
  return (
    <article
      id="page-portfolio-porsche-lobby"
      className="page-content portfolio-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            <sup aria-hidden={true} role="presentation">
              Commercial
            </sup>
            <div>Porsche Design Tower Lobby</div>
          </>
        }
        content={<>Sunny Isles Beach, FL</>}
        className="flipped portfolio-banner"
      >
        <DecorationFluid image="portfolio-porsche-lobby/banner.jpg" />
      </Banner>

      <Row id="summary" className="content-plus-media flipped">
        <Block className="block-content dropped">
          <div className="content">
            <dl className="details">
              <div className="detail">
                <dt className="title">Client</dt>
                <dd className="content">Porsche Design Tower</dd>
                <dd className="content">Dezer Development</dd>
              </div>
              <div className="detail">
                <dt className="title">Architect</dt>
                <dd className="content">
                  Sieger Suarez Architects (Miami, FL)
                </dd>
              </div>
              <div className="detail">
                <dt className="title">General Contractor</dt>
                <dd className="content">Coastal Construction (Miami, FL)</dd>
                <dd className="content">CoastalTishman (Miami,FL)</dd>
              </div>
              <div className="detail">
                <dt className="title">Interior Designer</dt>
                <dd className="content">Michael Wolk Design (Miami, FL)</dd>
              </div>
              <div className="detail">
                <dt className="title">Bottle Capacity</dt>
                <dd className="content">2,130</dd>
              </div>
            </dl>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-porsche-lobby/content-01.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-one" className="media-plus-media">
        <Block className="block-media">
          <DecorationFluid image="/portfolio-porsche-lobby/content-02.medium.jpg" />
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-porsche-lobby/content-03.medium.jpg" />
        </Block>
      </Row>

      <Row id="features" className="content-plus-media">
        <Block className="block-content dropped">
          <h4 className="title">Features</h4>
          <div className="content">
            <ul className="features">
              <li className="feature">
                Two separate, fully conditioned wine displays.
              </li>
              <li className="feature">
                Private wine lockers for wine storage.
              </li>
              <li className="feature">
                Fixed shelves in upper section for house storage.
              </li>
              <li className="feature">
                Mirror finished stainless steel towers over 14’ tall.
              </li>
              <li className="feature">Custom-made pullout drawers.</li>
            </ul>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-porsche-lobby/content-04.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-two" className="single-media">
        <Block className="block-media">
          <Decoration image="/portfolio-porsche-lobby/content-05.jpg" />
        </Block>
      </Row>

      <RelatedProducts
        id="related-products"
        products={[
          {
            slug: "custom-build",
            title: "Custom Build",
            url: "products/custom-build/",
          },
          {
            slug: "premier",
            title: "Premier Collection",
            url: "products/premier/",
          },
        ]}
      />

      <PortfolioTaglineMenu portfolioSlug="portfolio-porsche-lobby" />
    </article>
  )
}

export default ProjectPorscheLobbyPage
