import React from "react"
import Anchor from "../../core/components/anchor"
import Button from "../../client/compositions/button"
import Decoration from "../../client/compositions/decoration"
import Block from "../../core/components/block"
import Row from "../../core/components/row"

/** RelatedProducts component */
function RelatedProductsComponent(props) {
  const { products, children, className, ...passthruProps } = props

  return (
    <Row className={`${className}`} {...passthruProps}>
      <Block className="block-content">
        <h3 className="title">Related Products</h3>
        <div className="content">
          <ul className="related-products">
            {products.map(({ slug, title, url }) => (
              <li className="related-product" key={url}>
                <Anchor to={url}>
                  <Decoration image={`${slug}/viewer-thumbnail.small.jpg`} />
                  <h4 className="title">
                    {title.replace(/Collection$/, "")}{" "}
                    {title.match(/Collection$/) && (
                      <>
                        <br />
                        Collection
                      </>
                    )}
                  </h4>
                  <div className="content">
                    <Button image="arrow-right">Discover</Button>
                  </div>
                </Anchor>
              </li>
            ))}
          </ul>
        </div>
      </Block>
    </Row>
  )
}

/**
 * Compose
 */
const RelatedProducts = RelatedProductsComponent

export default RelatedProducts
